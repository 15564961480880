@use '../../styles/partials/mixins' as *;

.home {
  &__container {
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  &__title {
    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      height: 90vh;
      @include tablet {
        height: 50vh;
      }
    }
    font-family: "Fondamento";
    font-size: 5rem;
  }
  &__subheading {
    font-family: "Fondamento";
    font-size: 2.5rem;
  }
}

.projects {

  // &__container {
  //   border: 1px white solid;
  // }  
  &__heading {
    padding-bottom: 1rem;
    font-family: "Fondamento"
  }
  &__list-container {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__card {
    &-container {
      border: 3px solid white;
      border-radius: 10px;
      // padding: 0.5rem;
      padding-top: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: 1.5rem;
      @include tablet {
        // height: 20rem;
        width: 20rem;
        margin-right: 1rem;
      }
      @include desktop {
        width: 30rem;
        margin-right: 2rem;
      }
    }
    &-title {
      font-family: "Fondamento";
      font-size: 2rem;
      padding-bottom: 0.5rem;
    }
    &-description {
      padding-bottom: 0.5rem;
    }
    &-repo {
      margin-bottom: 0.5rem;
    }
  }
  &__link {
    &-container {
      display: flex;
      // flex-direction: column;
      flex-wrap: wrap;
    }
    // border: 1px solid white;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: #505050;
    &:focus {
      background-color: rgb(150, 150, 150);
    }
    @include tablet {
      &:hover {
        background-color: rgb(150, 150, 150);
      }
      &:focus {
        background-color: #c4c4c4;
      }
    }
  }
}