@use "./mixins" as *;
@use "./variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
}

body {
  margin: 0;
  background-color: black;
  // background-color: rgb(123, 123, 123);
  // @include tablet {
  //   background-color: rgb(67, 67, 67);
  // }
  // @include desktop {
  //   background-color: rgb(46, 46, 46);
  // }
  font-size: $global-font-size;
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

button {
  padding: 0;
  // box-shadow: none;
  // border: 3px solid white;
  border: none;
  // font-size: 1.5rem;
  font-size: $global-font-size;
  border-radius: 5px;
  background: transparent;
  // padding-inline: 0;
  // padding-block: 0;
  // border-width: 0;
}