@use '../../styles/partials/mixins' as *;

// ORIGINAL VIDEO (NO LONGER WORKS)

// .hamburger {
//   &__button {
//     border: 5px solid black;
//     border-radius: 1rem;
//     background: transparent;
//     &:hover .hamburger__svg {
//       &-top {
//         y: 45;
//         rotate: 45deg;
//       }
//       &-middle {
//         opacity: 0;
//       }
//       &-bottom {
//         y: 45;
//         rotate: -45deg;
//       }
//       &-lines {
//         transition:
//           y 300ms ease-in,
//           rotate 300ms ease-in 300ms,
//           opacity 0ms 300ms
//         ;
//       }
//     }
//   }
//   &__svg {
//     width: 500px;
//     &-lines {
//       transition:
//         y 300ms ease-in 300ms,
//         rotate 300ms ease-in,
//         opacity 0ms 300ms
//       ;
//       transform-origin: center;
//     }
//   }
// }


// BEM COMPLIANT BUT NOT WORKING ON MOBILE (TOP BUTTON)

// .hamburger {
//   &__button {
//     border: 5px solid black;
//     border-radius: 1rem;
//     background: transparent;
//   }

//   &__svg {
//     width: 500px;
    
//     &-top {
//       @include hamburger-closing-transition;

//       &--menuopen{
//         y: 45;
//         rotate: 45deg;
//         @include hamburger-opening-transition;
//       }      
//     }
  
//     &-middle {
//       @include hamburger-closing-transition;

//       &--menuopen {
//         opacity: 0;
//         @include hamburger-opening-transition;
//       }
//     }
  
//     &-bottom {
//       @include hamburger-closing-transition;

//       &--menuopen {
//         y: 45;
//         rotate: -45deg;
//         @include hamburger-opening-transition;
//       }
//     }
//   }
// }


// BOTTOM BUTTON

// .hamburger {
//   &__button {
//     border: 5px solid black;
//     border-radius: 1rem;
//     background: transparent;
//   }

//   &__svg {
//     width: 500px;

//     &-top {
//       @include hamburger-closing-transition;
//       // transform: translateY(-20px);

//       &--menuopen {
//         // y: 45;
//         // transform: translateY(20px);
//         // translate: 0px 20px;
//         // -webkit-transform: translateY(45);
//         // rotate: 45deg;
//         // rotate: 0deg;
//         // transform: rotate(45deg);
//         // @include hamburger-opening-transition;
//         animation-name: hamburger-top-open;
//         animation-duration: 1s;
//       }
//     }

//     &-middle {
//       @include hamburger-closing-transition;

//       &--menuopen {
//         opacity: 0;
//         @include hamburger-opening-transition;
//       }
//     }

//     &-bottom {
//       @include hamburger-closing-transition;
//       // transform: translateY(20px);

//       &--menuopen {
//         // y: 45;
//         transform: translateY(-20px);
//         // translate: 0px -20px;
//         // -webkit-transform: translateY(45);
//         rotate: -45deg;
//         // transform: rotate(-45deg);
//         @include hamburger-opening-transition;
//       }
//     }
//   }
// }

// @keyframes hamburger-top-open {
//   0% {}
//   33% {transform: translateY(20px) ;}
//   100% {transform: translateY(20px) rotate(45deg) ; transform-origin: center;}
// }

.sidebar {

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 60%;
    background-color: rgb(69, 69, 69);
    transition: 0.5s;
    transform: translateX(-100vw);

    &--active {
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgb(20, 20, 20);
      height: 100%;
      width: 60%;
      transition: 0.5s;

      @include tablet {
        display: none;
      }
    }
  }

  &__nav {
    position: absolute;
    margin-top: 10rem;
    height: 100%;

    &-item {
      font-size: 2rem;
      // padding-bottom: 5rem;
      margin-bottom: 2rem;
      margin-left: 2rem;
    }
  }
}

.header {
  padding: 1rem;
  margin-bottom: 3rem;
  // visibility: visible;
  // opacity: 50%;

  @include tablet {
    margin-bottom: 0;
  }
  
  &__hamburger-button {
    // z-index: 1000;
    position: absolute;
    border: 3px solid white;

    @include tablet {
      display: none;
    }

    &--active {
      position: fixed;
      border: 3px solid white;
      
      @include tablet {
        display: none;
      }
    }
    // display: none;
  }
  &__nav {
    display: none;

    @include tablet {
      padding-right: 3rem;
      display: flex;
      flex-direction: row-reverse;
    }

    &list {
      display: flex;

      &-item {
        padding-left: 2rem;
        display: flex;
        // justify-content: center;
        align-items: center;
      }
    }
  }
}