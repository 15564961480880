// @font-face {
//   font-family: "Avenir Next LT Pro";
//   src: url("../../assets/fonts/AvenirNextLTPro.ttf") format("truetype"),
//     url("../../assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
//   font-weight: 400
// }

// @font-face {
//   font-family: "Avenir Next LT Pro";
//   src: url("../../assets/fonts/AvenirNextLTProBold.ttf") format("truetype"),
//     url("../../assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
//   font-weight: 700
// }

@font-face {
  font-family: "Fondamento";
  src: url("../../assets/fonts/Fondamento-Regular.ttf") format("truetype");
  font-weight: 400
}

@font-face {
  font-family: "Fondamento Italic";
  src: url("../../assets/fonts/Fondamento-Italic.ttf") format("truetype");
  font-weight: 400
}