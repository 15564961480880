@use '../../styles/partials/mixins' as *;

.cvpage {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  &__button {
    color: white;
    cursor: pointer;
    background-color: rgb(175, 175, 175);
    padding: 1rem;
    margin: 3rem;
  }
  &__cv-object {
    width: calc(100vw - 6rem);
    height: calc((100vw - 6rem) * 1.37);
    max-width: 55rem;
    max-height: 72rem;
  }
}