@use "variables" as *;

// mobile = 320px+

// tablet = 768px+
@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

// desktop = 1280px+
@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin link-button {
  
}


// BEM COMPLIANT BUT NOT WORKING ON MOBILE (TOP BUTTON)

// @mixin hamburger-opening-transition {
//   transition:
//     y 300ms ease-in,
//     rotate 300ms ease-in 300ms,
//     opacity 0ms 300ms
//   ;
//   transform-origin: center;
// }

// @mixin hamburger-closing-transition {
//   transition:
//     y 300ms ease-in 300ms,
//     rotate 300ms ease-in,
//     opacity 0ms 300ms
//   ;
//   transform-origin: center;
// }




// BOTTOM BUTTON

// @mixin hamburger-opening-transition {
//   transition:
//     transform 300ms ease-in,
//     // transform(translateY) 300ms ease-in,
//     // translate 300ms ease-in,
//     rotate 300ms ease-in 300ms,
//     // transform 300ms ease-in 300ms,
//     // translateY 300ms ease-in,
//     // transform 300ms ease-in 300ms,
//     opacity 0ms 300ms
//   ;
//   // transition: transform 300ms ease-in;
//   // transition: rotate 300ms ease-in 300ms;
//   // transition: opacity 0ms 300ms;
//   transform-origin: center;
  
//   // -webkit-transition: 
//   //   y 300ms ease-in,
//   //   rotate 300ms ease-in 300ms,
//   //   opacity 0ms 300ms
//   // ;
// }

// @mixin hamburger-closing-transition {
//   transition:
//     rotate 300ms ease-in,
//     // transform 300ms ease-in,
//     transform 300ms ease-in 300ms,
//     translate 300ms ease-in 300ms,
//     // rotate 300ms ease-in 300ms,
//     // opacity 0ms 300ms,
//     // rotate 3000ms ease-in,
//   ;
//   transform-origin: center;
//   // -webkit-transition:
//   //   y 300ms ease-in 300ms,
//   //   rotate 300ms ease-in,
//   //   opacity 0ms 300ms
//   // ;
// }